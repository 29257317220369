<template>
  <AppContainer id="all" class="my-5">
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              v-model="sortBy"
              id="sortBySelect"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value>-- none --</option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Initial sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="initialSortSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="sortDirection"
            id="initialSortSelect"
            size="sm"
            :options="['asc', 'desc', 'last']"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter On"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          description="Leave all unchecked to filter on all data"
          class="mb-0"
        >
          <b-form-checkbox-group v-model="filterOn" class="mt-1">
            <b-form-checkbox value="ticket_id">MID</b-form-checkbox>
            <b-form-checkbox value="title">Title</b-form-checkbox>
            <b-form-checkbox value="published">Published</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      show-empty
      small
      stacked="md"
      :items="tickets"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:cell(actions)="row">
        <b-button-toolbar>
          <b-button-group size="sm">
            <b-button @click="row.toggleDetails"
              >{{ row.detailsShowing ? "Hide" : "Show" }} Details</b-button
            >
            <b-button
              @click="onPublish(row.item)"
              :variant="row.item.published == 1 ? 'danger' : 'success'"
              >{{ row.item.published == 1 ? "Unpublish" : "Publish" }}</b-button
            >
            <b-button
              variant="primary"
              :to="`/tickets/edit/${row.item.ticket_id}`"
              >Edit</b-button
            >
            <b-button variant="danger" @click="onDelete(row.item)"
              >Delete</b-button
            >
          </b-button-group>
        </b-button-toolbar>
      </template>

      <template v-slot:row-details="row">
        <b-card>
          <b-img-lazy
            v-bind="{ width: 200, height: 300 }"
            thumbnail
            fluid
            :src="row.item.imgurl"
            alt="Center image"
          ></b-img-lazy>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>
    <b-button to="/tickets/create">Add New Ticket</b-button>
  </AppContainer>
</template>

<script>
const AppContainer = () => import("@/core/Components/App/AppContainer.vue");
import { api } from "../../resource";
export default {
  name: "all",
  data() {
    return {
      tickets: [],
      fields: [
        {
          key: "ticket_id",
          label: "TID",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "item1",
          label: "Item",
          sortable: true,
          class: "text-center",
        },
        {
          key: "published",
          label: "is Published",
          formatter: (value) => {
            return value == 1 ? "Yes" : "No";
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        ticket_id: "info-modal",
        title: "",
        description: "",
      },
    };
  },
  created() {
    api.create("tickets/all", {}).then((res) => {
      this.tickets = res.data;
      this.totalRows = this.tickets?.length || 0;
    });
  },
  methods: {
    onPublish(ticket) {
      api.create("tickets/publish", ticket).then(() => {
        ticket.published = ticket.published == 1 ? 0 : 1;
      });
    },
    onDelete(ticket) {
      api.create("tickets/delete", ticket).then(() => {
        const i = this.tickets.findIndex(
          (m) => m.ticket_id == ticket.ticket_id
        );
        this.tickets.splice(i, 1);
        this.$swal('Success', 'Ticket successfully Deleted!', 'success')
      });
    },
    onFiltered(filteredtickets) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredtickets.length;
      this.currentPage = 1;
    },
  },
  components: {
    AppContainer,
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
};
</script>
